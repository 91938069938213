#cookiesModal {
	font-family: Arial;
	& .modal-title  {
		color: #000000;
		font-size:40px;
	}
	& .close {
		font-size: 60px;
	}
	& .modal-body {
		color: #000000;
		font-size: 15px;
		line-height: 17px;
		& .title {
			font-size: 20px;
		}
		& .disabled {
			color: #C1C1C1;
			& span.disabled {
				margin: 0 24px ;
			}
		}
		& .enabled {
			color: #0090E2;
		}
	}
}