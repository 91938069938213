.img-carousel-news-une {
    color: white;
    font-size: 24px;
    text-align: center;
}    
#img-carousel-news {
    .carousel-control-prev, .carousel-control-next {
        top: unset;
        bottom: 4px;
    }
    img {
        width: 100%;
        height: 178px;
    }
    .img-carousel-news-date {
        font-size: 13px;
        text-transform: uppercase;
    }
    .img-carousel-news-texte {
        font-size: 16px;
        line-height: 22px;
        height: 80px;
        p {
            margin-bottom: 0;
        }
        overflow: hidden;
    }
    .btn-carousel-suite {
        color: white;
        font-size: 11px;
        font-weight: bold;
        background-color: #7381B6;
        border: 1px solid #FFFFFF;
        border-radius: 50px;
        opacity: 1;
        padding: 10px 20px;
        letter-spacing: 1px;
        display: block;
        width: fit-content;
        margin: 16px auto;
        &:hover {
            text-decoration: none;
        }
    }    
}
.site-home-img-2 {
    height: 475px;
}
.img-home-gif {
    height: 180px;
    margin: 0 auto;
    display: block;
}