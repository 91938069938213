.text-decoration-underline {
    text-decoration: underline;
}
.bg-7381B6 {
    background-color: #7381B6;
}
.bg-87C3E3 {
    background-color: #87C3E3;
}
.bg-0092D5 {
    background-color: #0092D5;
}
.bg-70AADD {
    background-color: #70AADD;
}
.text-383838 {
    color: #383838;
}
.text-0092D5 {
    color: #0092D5;
}
.font-size-12px {
    font-size: 12px;
    line-height: 18px;
}
.font-size-16px {
    font-size: 16px;
    line-height: 22px;
}
.font-size-20px {
    font-size: 20px;
}
.font-size-30px {
    font-size: 30px;
}
.color-0092D5 {
    color: #0092D5;
}
.color-383838 {
    color: #383838;
}
.color-6F5C9D {
    color: #6F5C9D;
}
.w-fit-content {
    width: fit-content;
}
.text-decoration-underline {
    text-decoration: underline;
}
.font-weight-bolder {
    font-weight: 900 !important;
}

body {
    font-size: 16px;
    line-height: 22px;

    // Fixed top menu
    padding-top: 28px;
    @include media-breakpoint-up(lg) {
        padding-top: 49px;
    }
    &.home {
        padding-top: 137px;
        @include media-breakpoint-up(lg) {
            padding-top: 154px;
        }
    }
}
/*
* HEADER
*/
.header-img-logo {
    width: 150px;
}
.header-img-logo-home {
    width: 350px;
}
@include media-breakpoint-up(lg) {
    .header-img-logo {
        width: 200px;
    }
}

.navbar-light .navbar-nav .nav-link {
    @include media-breakpoint-between(lg, xl) {
        font-size: 14px;
        .fas {
            font-size: 30px;
            position: relative;
            top: -8px;
        }
    }    
    @include media-breakpoint-up(xl) {
        font-size: 18px;
        .fas {
            font-size: 38px;
            position: relative;
            top: -8px;
        }
    }
    color: #87C3E3;
    text-transform: uppercase;
    .fas {
        font-size: 38px;
        position: relative;
        top: -8px;
    }
    &:hover {
        color: #6F85B8;
    }
    &:focus {
        color: #6F85B8;
    }
    &.active {
        color: #6F85B8;
    }
}
/*
* Img Langue
*/
.site-img-home-change-langue {
    position: absolute;
    top: 10px;
    right: 20px;
    height: 32px;
    width: 32px;
    display: block;
    margin-left: 20px;
    background-size: 100%;
    &:hover {
        background-position-y: 100%;
    }
}
.site-img-change-langue {
    position: relative;
    @include media-breakpoint-up(xl) {
        top: 5px;
    }
    height: 32px;
    width: 32px;
    display: block;
    margin-left: 20px;
    background-size: 100%;
    &:hover {
        background-position-y: 100%;
    }
}
.site-img-change-langue-xs {
    height: 16px;
    width: 16px;
    display: inline-block;
    margin-right: 20px;
    background-size: 100%;
    position: relative;
    top: 3px;
    left: 10px;
    &:hover {
        background-position-y: 100%;
    }
}
.site-img-change-langue-en {
    background-image: url('/img/drapeau-anglais.png');
}
.site-img-change-langue-fr {
    background-image: url('/img/drapeau-france.png');
}

/*
* FOOTER
*/
.border-gradient {
    background: transparent linear-gradient(90deg, #F5B8B1 0%, #E5236C 52%, #644090 100%) 0% 0% no-repeat padding-box;
    height: 5px;
    width: 100%;
}
footer {
    font-size: 13px;
    color: #383838;
}
/*
* COOKIE CONSENT
*/
.alert-banner {
    color: white;
    background-color: #7381B6;
    border-color: #7381B6;
    a {
        color: #87C3E3;
        &:hover {
            color: #87C3E3;
        }
    }
    & .btn-banner {
        color: #212529;
        background-color: #87C3E3;
        border-color: #87C3E3;
    }
}
/*
* SCROLLTOP
*/
#btnScrollTop {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 99;
    border: none;
    outline: none;
    background-color: #7381B6;
    color: white;
    cursor: pointer;
    padding: 10px 15px;
    font-size: 18px;
    &:hover {
    color: #7381B6;
    background-color: white;
    }
}


/**
* MENU LANGUE
*/
.app-flag {
    margin-top: 8px;
    & ul {
        list-style-type: none;
    }
}
.dropdown-menu {
    border-radius:4px;
    min-width: 0;
    box-shadow:none;
    margin-top:0;
    &:before {
        content: "";
        border-bottom: 10px solid #fff;
        border-right: 10px solid transparent;
        border-left: 10px solid transparent;
        position: absolute;
        top: -10px;
        right: 16px;
        z-index: 10;        
    }
    &:after {
        content: "";
        border-bottom: 12px solid #ccc;
        border-right: 12px solid transparent;
        border-left: 12px solid transparent;
        position: absolute;
        top: -12px;
        right: 14px;
        z-index: 9;
    }
}
