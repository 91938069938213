.actualites {
    color: white;
    margin-left: 0;
    margin-right: 0;
    p {
        margin-bottom: 0;
    }
    &:nth-child(even) {
        background-color: #7381B6;
    }
    &:nth-child(odd) {
        background-color: #87C3E3;
    }
}
