.li-margin-bottom-15px {
    li {
        margin-bottom: 15px;

    }
}
.site-vignette-7381B6 {
    background-color: #7381B6;
    font-size: 13px;
    color: white;
    text-align: center;
    width: fit-content;
    margin: 0 auto 15px;
    padding: 2px 10px;
}
.savoir-faire-hr {
    border-top: 1px solid #707070;
}
#video-1, #video-2, #video-3, #video-4 {
    cursor: pointer;
}